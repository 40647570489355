
import { Component, Vue } from "vue-property-decorator";
import { FirebaseAppAuth } from "@/firebase/firebase-app";
import { Action } from "vuex-class";
import firebase from "firebase";
import { Callback } from "@/types/misc";
import { sharedRouteNames } from "@/route-names/shared";
import namespaces from "@/store/namespaces";
import { advertiserRouteNames } from "@/route-names/advertiser";
import { storeOwnerRouteNames } from "@/route-names/store-owner";
import { AuthorizedUser } from "@/types/users";
import { USER_ROLES } from "@/statics/user-roles";
import { ResetCampaign } from '@/store/modules/campaign-wizard/types';
import { regulatorRouteNames } from '@/route-names/regulator';

@Component({})
export default class AdminLogin extends Vue {
  @Action("adminLoginAsUser", { namespace: namespaces.AuthModule })
  public adminLoginAsUser!: Callback<
    firebase.User | null,
    Promise<AuthorizedUser>
  >;

  @Action('resetCampaign', { namespace: namespaces.CampaignWizardModule })
  public resetCampaign!: ResetCampaign;

  @Action('resetAllCampaigns', { namespace: namespaces.IncomingCampaignsModule })
  public resetAllIncomingCampaigns!: ResetCampaign;

  userRole = '';
  async created() {
    try {
      this.resetCampaign();
      this.resetAllIncomingCampaigns();
      const token = window.atob(this.$route.params.token);
      this.userRole = window.atob(this.$route.params.userRole);
      if (token) {
        const userCredential = await FirebaseAppAuth.signInWithCustomToken(
          token
        );
        const user = userCredential.user;
        if (user) {
          const { ROLES: roles } = await this.adminLoginAsUser(user);
          const [role] = roles;
          window.localStorage.setItem('user', JSON.stringify(user))
          window.localStorage.setItem('userInfo', JSON.stringify({ ROLES: roles, UID: user.uid }))
          const redirectTo = {
            [USER_ROLES.ADVERTISER.VAL]: advertiserRouteNames.MY_CAMPAIGNS,
            [USER_ROLES.STORE_OWNER.VAL]: storeOwnerRouteNames.DASHBOARD,
            [USER_ROLES.REGULATOR.VAL]: regulatorRouteNames.DASHBOARD,
          }
          if (redirectTo[role.VAL]) {
            this.$router.push({ ...redirectTo[role.VAL] });
          }
        }
      }
    } catch (e) {
      this.$router.push({ ...sharedRouteNames.PAGE_NOT_FOUND });
    }
  }
}
